<template>
	<div id="passengerFlowReport">
		<jy-query ref="form" :model="form">
			<jy-query-item prop="routeId" label="线路:">
				<!-- <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.routeName"></el-input>
                </div> -->
				<el-select v-model="form.routeId" placeholder="请选择线路">
					<el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId"> </el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item prop="siteName" label="站点:" label-width="70px">
				<el-select v-model="form.siteName" placeholder="请选择">
					<el-option v-for="item in zdoptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item prop="vehicleNo" label="车牌号:">
				<el-input placeholder="请输入" v-model="form.vehicleNo"></el-input>
			</jy-query-item>
			<jy-query-item label="查询日期:" prop="searchYearMonth">
				<el-date-picker v-model="form.searchYearMonth" value-format="yyyy-MM" type="month" placeholder="选择年月">
				</el-date-picker>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')">重置</el-button>
				<!-- <el-row> -->
				<!-- <el-button type="primary">导出</el-button> -->
				<!-- </el-row> -->
			</template>
		</jy-query>
		<el-row>
			<el-col :span="12">
				<el-card>
					<div slot="header">
						<span>上行站点</span>
					</div>
					<div>
						<jy-table max-height="595" :data="upStreamList">
							<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
							<jy-table-column prop="routeName" label="线路"></jy-table-column>
							<jy-table-column prop="orgName" label="所属机构" min-width="180"></jy-table-column>
							<jy-table-column prop="vehicleNo" label="车牌号"></jy-table-column>
							<jy-table-column prop="siteName" label="站点名称"></jy-table-column>
							<jy-table-column prop="sumGetOn" label="累计上车人数"></jy-table-column>
						</jy-table>
						<div class="onCarBox">
							上车人数总计：{{ upSumGetOn }}
							<div class="secondBox">下车人数总计：{{ upSumGetOff }}</div>
						</div>
						<!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-sizes="global.pageSizesList" :page-size="pageSize" :layout="global.pageLayout" :total="total">
                                </el-pagination> -->
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card>
					<div slot="header">
						<span>下行站点</span>
					</div>
					<div>
						<jy-table max-height="595" :data="downStreamList">
							<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
							<jy-table-column prop="routeName" label="线路"></jy-table-column>
							<jy-table-column prop="orgName" label="所属机构" min-width="180"></jy-table-column>
							<jy-table-column prop="vehicleNo" label="车牌号"></jy-table-column>
							<jy-table-column prop="siteName" label="站点名称"></jy-table-column>
							<jy-table-column prop="sumGetOn" label="累计上车人数"></jy-table-column>
						</jy-table>
						<div class="onCarBox">
							上车人数总计：{{ downSumGetOn }}
							<div class="secondBox">下车人数总计：{{ downSumGetOff }}</div>
						</div>
						<!-- <div class="jy_pagination">
                                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-sizes="global.pageSizesList" :page-size="pageSize" :layout="global.pageLayout" :total="total">
                                </el-pagination>
                            </div> -->
					</div>
				</el-card>
			</el-col>
		</el-row>
		<institutions-tree
			ref="institutions"
			@addInstitutions="addInstitutions"
			url="/waybillOperate/getTree"
			title="选择线路组织"
			:defaultProps="props"
		></institutions-tree>
	</div>
</template>
<script>
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
export default {
	data() {
		return {
			props: {
				children: "children",
				label: "text"
			},
			routeId: "",
			upSumGetOn: "0",
			upSumGetOff: "0",
			downSumGetOn: "0",
			downSumGetOff: "0",

			form: {
				routeName: "",
				routeId: "",
				vehicleNo: "",
				siteName: "",
				searchYearMonth: ""
			},
			pageSize: 10,
			total: 0,
			pageIndex: 1,
			upStreamList: [
				{
					routeName: "",
					vehicleNo: "",
					siteName: "",
					sumGetOn: ""
				}
			],
			downStreamList: [
				{
					routeName: "",
					vehicleNo: "",
					siteName: "",
					sumGetOn: ""
				}
			],
			zdoptions: [],
			routeOptions: []
		};
	},
	created() {
		this.getList();
		this.getDate();
	},
	activated() {
		this.getRouteList();
	},
	watch: {
		"form.routeName": {
			handler(val) {
				if (val) {
					this.routeId = this.form.routeId;
					this.getAllList();
				}
			},
			immediate: true
		}
	},
	components: {
		institutionsTree
	},
	methods: {
		getRouteList() {
			let url = "/baseinforoute/queryCurrentAuthorityRoute";
			this.$http.post(url).then(({ detail }) => {
				this.routeOptions = detail;
			});
		},
		getAllList() {
			let url = "/baseinfosite/querySiteNameByRouteId";
			let o = {
				routeId: this.routeId
			};
			let option = [
				{
					label: "全部",
					value: ""
				}
			];
			this.$http.post(url, o).then(({ detail }) => {
				detail.forEach(item => {
					option.push({
						label: item.name,
						value: item.name
					});
				});
				this.zdoptions = option;
			});
		},
		getDate() {
			var date = new Date();
			var year = date.getFullYear();
			var month = date.getMonth();
			if (month == 0) {
				month = 12;
			}
			if (month < 10) {
				month = "0" + month;
			}
			var nowDate = year + "-" + month;
			this.form.searchYearMonth = nowDate;
		},
		getList() {
			let option = {
				...this.form
				// pageIndex: this.pageIndex,
				// pageSize: this.pageSize
			};
			let url = "/statpassengerflowmonth/queryPageDataList";
			console.log(option);
			this.$http.post(url, option).then(res => {
				if (res.retain == "1") {
					this.upStreamList = [];
					this.downStreamList = [];
					this.$message({
						message: res.error,
						type: "warning"
					});
				} else {
					this.upStreamList = res.detail.upStreamList;
					this.downStreamList = res.detail.downStreamList;
					this.upSumGetOn = res.detail.upSumGetOn;
					this.upSumGetOff = res.detail.upSumGetOff;
					this.downSumGetOn = res.detail.downSumGetOn;
					this.downSumGetOff = res.detail.downSumGetOff;
					// this.total = res.detail.total
				}
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.form.routeId = "";
			this.oncheck();
		},
		// 线路组织
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		addInstitutions(data) {
			console.log(data);
			this.form.routeId = data.id;
			this.form.routeName = data.text;
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		}
	}
};
</script>
<style lang="scss" scoped="passengerFlowReport">
#passengerFlowReport {
	.select_box {
		margin-left: 50px;
	}

	.onCarBox {
		padding-top: 10px;
		font-size: 12px;

		.secondBox {
			display: inline-block;
			margin-left: 20px;
		}
	}
}
</style>
